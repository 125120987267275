import { gql } from 'apollo-boost';

export const ALL_CHALLENGES = gql`
  query getChallengeList($filter: ChallengeFilter) {
    getChallengeList(filter: $filter) {
      id
      name
      bannerImage
      bannerImageSmall
      premium
      createdAt
      published
      goals {
        id
        name
        image
      }
      shortDescription
      taskCount
    }
  }
`;

export const ALL_CHALLENGES_PANEL = gql`
  query getChallengeListPanel($filter: ChallengesFilter!) {
    getChallengeListPanel(filter: $filter) {
      challenges {
        id
        name
        bannerImage
        premium
        createdAt
        published
      }
      totalCount
    }
  }
`;

export const GET_CHALLENGE = gql`
  query getChallenge($id: String!) {
    getChallenge(id: $id) {
      id
      published
      bannerImage
      bannerImageSmall
      video
      videoTitle
      videoDescription
      videoBanner
      videos {
        id
        url
        thumbnail
        title
        description
      }
      description
      difficulty
      duration
      extraInformations
      faqs
      type
      healthProblems {
        id
        name
        description
      }
      goals {
        id
        name
      }
      goalPriorities {
        id
        goal {
          id
          name
        }
        priority
      }
      joinChallengeDescription
      _userInputs {
        id
        name
        inputId
        type
        question
        defaultValue
        placeholder
        description
      }
      name
      premium
      isTutorial
      onHomePage
      featured
      shortDescription
      notifications {
        id
        name
        userInput {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        userInputs {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        message
        duration
        repeating
        absoluteTime
        type {
          id
          name
        }
        sound
        iconType {
          id
          name
        }
      }
      challengeContents {
        recipes {
          id
          name
          image
          premium
        }
        recipeTypes {
          id
          description
          name
          image
        }
        articles {
          id
          name
          image
          premium
        }
      }
      recommendedChallenges {
        bannerImage
        bannerImageSmall
        goals {
          name
        }
        id
        name
        premium
        shortDescription
        taskCount
      }
      recommendedMiniChallenges {
        userBadges {
          badge
        }
        bannerImage
        bannerImageSmall
        id
        name
        premium
        shortDescription
      }
      recommendedPrograms {
        id
        image
        imageSmall
        name
        premium
        published
        shortDescription
      }
      reminders {
        id
        name
        userInput {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        userInputs {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        message
        duration
        repeating
        absoluteTime
        type {
          id
          name
        }
      }
      benefits {
        id
        name
        description
        image
        goals {
          id
          name
        }
        article {
          id
          name
        }
        recipe {
          id
          name
        }
        action {
          id
          name
          type {
            id
            name
          }
          article {
            id
            name
          }
          recipe {
            id
            name
          }
          redirect {
            url
            text
          }
        }
      }
      tasks
      taskGroups
      challengeDaysDefault
      challengeDaysText
      extraInformations
      extraInformationsTitle
      surveyQuestions {
        id
        question
        singleSelection
        answers {
          id
          answerText
          afterAnswerMessage
          disableOthers
          type {
            id
            name
          }
          actionTitle
          actionMessage
          recommendedArticleId
          recommendedChallengeId
          recommendedProgramId
          recommendedTaskIds
        }
      }
      abandonmentPage {
        title
        answers
      }
      nextType
      nextId
      shareImageSquare
    }
  }
`;

export const CREATE_CHALLENGE = gql`
  mutation createChallenge(
    $bannerImage: String
    $bannerImageSmall: String
    $video: String
    $videoTitle: String
    $videoDescription: String
    $videoBanner: String
    $videos: String
    $description: String
    $difficulty: Difficulty
    $joinChallengeDescription: String
    $totalExperiencePoints: String
    $totalHealthyCoins: String
    $duration: String
    $extraInformations: [ExtraInformationInput]
    $faqs: [FAQInput]
    $published: Boolean
    $goalPriorities: [ChallengeGoalPriorityUpdateInput]
    $_userInputs: [String]
    $tasks: String
    $taskGroups: String
    $benefits: String
    $extraInformationsTitle: String
    $healthProblems: [HealthProblemInput]
    $name: String!
    $premium: Boolean
    $isTutorial: Boolean
    $onHomePage: Boolean
    $featured: Boolean
    $shortDescription: String
    $userInputs: String
    $contents: String
    $challengeContents: String
    $recommendedChallenges: String
    $recommendedMiniChallenges: String
    $recommendedPrograms: String
    $reminders: String
    $notifications: String
    $surveyQuestions: String
    $type: ChallengeType
    $challengeDaysDefault: Int
    $challengeDaysText: String
    $abandonmentPage: String
    $nextType: NextType
    $nextId: ID
    $shareImageSquare: String
  ) {
    createChallenge(
      bannerImage: $bannerImage
      bannerImageSmall: $bannerImageSmall
      video: $video
      videoTitle: $videoTitle
      videoDescription: $videoDescription
      videoBanner: $videoBanner
      videos: $videos
      totalExperiencePoints: $totalExperiencePoints
      totalHealthyCoins: $totalHealthyCoins
      description: $description
      difficulty: $difficulty
      duration: $duration
      _userInputs: $_userInputs
      extraInformations: $extraInformations
      faqs: $faqs
      tasks: $tasks
      published: $published
      taskGroups: $taskGroups
      goalPriorities: $goalPriorities
      extraInformationsTitle: $extraInformationsTitle
      benefits: $benefits
      healthProblems: $healthProblems
      joinChallengeDescription: $joinChallengeDescription
      name: $name
      premium: $premium
      isTutorial: $isTutorial
      onHomePage: $onHomePage
      featured: $featured
      shortDescription: $shortDescription
      userInputs: $userInputs
      contents: $contents
      challengeContents: $challengeContents
      recommendedChallenges: $recommendedChallenges
      recommendedMiniChallenges: $recommendedMiniChallenges
      recommendedPrograms: $recommendedPrograms
      reminders: $reminders
      notifications: $notifications
      surveyQuestions: $surveyQuestions
      type: $type
      challengeDaysDefault: $challengeDaysDefault
      challengeDaysText: $challengeDaysText
      abandonmentPage: $abandonmentPage
      shareImageSquare: $shareImageSquare
      nextType: $nextType
      nextId: $nextId
    ) {
      id
      bannerImage
      bannerImageSmall
      video
      videoTitle
      videoDescription
      videoBanner
      videos {
        id
        url
        thumbnail
        title
        description
      }
      description
      difficulty
      published
      duration
      extraInformations
      faqs
      _userInputs {
        id
        name
        inputId
        type
        question
        placeholder
        defaultValue
      }
      type
      healthProblems {
        id
        name
        description
      }
      goals {
        id
        name
      }
      goalPriorities {
        id
        goal {
          id
          name
        }
        priority
      }
      joinChallengeDescription
      name
      premium
      challengeDaysDefault
      challengeDaysText
      isTutorial
      onHomePage
      featured
      shortDescription
      benefits {
        id
        name
        description
        image
        goals {
          id
          name
        }
        article {
          id
          name
        }
        recipe {
          id
          name
        }
        action {
          id
          name
          type {
            id
            name
          }
          article {
            id
            name
          }
          recipe {
            id
            name
          }
          redirect {
            url
            text
          }
        }
      }
      tasks
      taskGroups
      extraInformations
      extraInformationsTitle
      notifications {
        id
        name
        userInput {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        userInputs {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        message
        duration
        repeating
        absoluteTime
        type {
          id
          name
        }
      }
      challengeContents {
        recipes {
          id
          name
          image
          premium
        }
        recipeTypes {
          id
          description
          name
          image
        }
        articles {
          id
          name
          image
          premium
        }
      }
      recommendedChallenges {
        bannerImage
        bannerImageSmall
        goals {
          name
        }
        id
        name
        premium
        shortDescription
        taskCount
      }
      recommendedMiniChallenges {
        userBadges {
          badge
        }
        bannerImage
        bannerImageSmall
        id
        name
        premium
        shortDescription
      }
      recommendedPrograms {
        id
        image
        imageSmall
        name
        premium
        published
        shortDescription
      }
      reminders {
        id
        name
        userInput {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        userInputs {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        message
        duration
        repeating
        absoluteTime
        type {
          id
          name
        }
      }
      surveyQuestions {
        id
        question
        answers {
          id
          answerText
          afterAnswerMessage
          disableOthers
          type {
            id
            name
          }
          actionTitle
          actionMessage
          recommendedArticleId
          recommendedChallengeId
          recommendedProgramId
          recommendedTaskIds
        }
      }
      abandonmentPage {
        title
        answers
      }
      nextId
      nextType
      shareImageSquare
    }
  }
`;

export const UPDATE_CHALLENGE = gql`
  mutation updateChallenge(
    $id: String!
    $bannerImage: String
    $bannerImageSmall: String
    $video: String
    $videoTitle: String
    $videoDescription: String
    $videoBanner: String
    $videos: String
    $totalExperiencePoints: String
    $totalHealthyCoins: String
    $description: String
    $difficulty: Difficulty
    $published: Boolean
    $duration: String
    $extraInformations: [ExtraInformationInput]
    $faqs: [FAQInput]
    $goalPriorities: [ChallengeGoalPriorityUpdateInput]
    $joinChallengeDescription: String
    $_userInputs: [String]
    $tasks: String
    $taskGroups: String
    $benefits: String
    $extraInformationsTitle: String
    $healthProblems: [HealthProblemInput]
    $name: String!
    $premium: Boolean
    $isTutorial: Boolean
    $onHomePage: Boolean
    $featured: Boolean
    $shortDescription: String
    $userInputs: String
    $contents: String
    $challengeContents: String
    $recommendedChallenges: String
    $recommendedMiniChallenges: String
    $recommendedPrograms: String
    $reminders: String
    $notifications: String
    $surveyQuestions: String
    $type: ChallengeType
    $challengeDaysDefault: Int
    $challengeDaysText: String
    $abandonmentPage: String
    $nextType: NextType
    $nextId: ID
    $shareImageSquare: String
  ) {
    updateChallenge(
      id: $id
      bannerImage: $bannerImage
      bannerImageSmall: $bannerImageSmall
      video: $video
      videoTitle: $videoTitle
      videoDescription: $videoDescription
      videoBanner: $videoBanner
      videos: $videos
      totalExperiencePoints: $totalExperiencePoints
      totalHealthyCoins: $totalHealthyCoins
      description: $description
      difficulty: $difficulty
      duration: $duration
      published: $published
      isTutorial: $isTutorial
      extraInformations: $extraInformations
      faqs: $faqs
      tasks: $tasks
      taskGroups: $taskGroups
      goalPriorities: $goalPriorities
      joinChallengeDescription: $joinChallengeDescription
      _userInputs: $_userInputs
      extraInformationsTitle: $extraInformationsTitle
      benefits: $benefits
      healthProblems: $healthProblems
      name: $name
      premium: $premium
      onHomePage: $onHomePage
      featured: $featured
      shortDescription: $shortDescription
      userInputs: $userInputs
      contents: $contents
      challengeContents: $challengeContents
      recommendedChallenges: $recommendedChallenges
      recommendedMiniChallenges: $recommendedMiniChallenges
      recommendedPrograms: $recommendedPrograms
      reminders: $reminders
      notifications: $notifications
      surveyQuestions: $surveyQuestions
      type: $type
      challengeDaysDefault: $challengeDaysDefault
      challengeDaysText: $challengeDaysText
      abandonmentPage: $abandonmentPage
      nextType: $nextType
      nextId: $nextId
      shareImageSquare: $shareImageSquare
    ) {
      id
      bannerImage
      bannerImageSmall
      video
      videoTitle
      videoDescription
      videoBanner
      videos {
        id
        url
        thumbnail
        title
        description
      }
      description
      difficulty
      challengeDaysDefault
      challengeDaysText
      published
      duration
      extraInformations
      faqs
      type
      healthProblems {
        id
        name
        description
      }
      goals {
        id
        name
      }
      goalPriorities {
        id
        goal {
          id
          name
        }
        priority
      }
      joinChallengeDescription
      _userInputs {
        id
        name
        inputId
        type
        question
        placeholder
        defaultValue
      }
      name
      premium
      isTutorial
      onHomePage
      featured
      shortDescription
      benefits {
        id
        name
        description
        image
        goals {
          id
          name
        }
        article {
          id
          name
        }
        recipe {
          id
          name
        }
        action {
          id
          name
          type {
            id
            name
          }
          article {
            id
            name
          }
          recipe {
            id
            name
          }
          redirect {
            url
            text
          }
        }
      }
      tasks
      taskGroups
      extraInformations
      extraInformationsTitle
      notifications {
        id
        name
        userInput {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        userInputs {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        message
        duration
        repeating
        absoluteTime
        type {
          id
          name
        }
      }
      challengeContents {
        recipes {
          id
          name
          image
          premium
        }
        recipeTypes {
          id
          description
          name
          image
        }
        articles {
          id
          name
          image
          premium
        }
      }
      recommendedChallenges {
        bannerImage
        bannerImageSmall
        goals {
          name
        }
        id
        name
        premium
        shortDescription
        taskCount
      }
      recommendedMiniChallenges {
        userBadges {
          badge
        }
        bannerImage
        bannerImageSmall
        id
        name
        premium
        shortDescription
      }
      recommendedPrograms {
        id
        image
        imageSmall
        name
        premium
        published
        shortDescription
      }
      reminders {
        id
        name
        userInput {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        userInputs {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        message
        duration
        repeating
        absoluteTime
        type {
          id
          name
        }
      }
      surveyQuestions {
        id
        question
        singleSelection
        answers {
          id
          answerText
          afterAnswerMessage
          disableOthers
          type {
            id
            name
          }
          actionTitle
          actionMessage
          recommendedArticleId
          recommendedChallengeId
          recommendedProgramId
          recommendedTaskIds
        }
      }
      abandonmentPage {
        title
        answers
      }
      nextId
      nextType
      shareImageSquare
    }
  }
`;

export const ALL_CHALLENGES_DROPDOWN = gql`
  query getChallengeList {
    getChallengeList(filter: { noActiveCheck: true }) {
      id
      name
    }
  }
`;
