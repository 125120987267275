import { ApolloClient } from 'apollo-client';
import { ApolloLink, concat } from 'apollo-link';
import { DEFAULT_LANGUAGE } from '../constants';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import DebounceLink from 'apollo-link-debounce';
import authService from 'src/services/authService';

const uri = process.env.REACT_APP_API_URL || '';
const cache = new InMemoryCache();
const httpLink = new HttpLink({ uri });
const DEFAULT_DEBOUNCE_TIMEOUT = 100;

const authMiddleware = new ApolloLink.from([
  new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
  (operation, forward) => {
    // add the authorization to the headers
    const token = localStorage.getItem('accessToken');
    const language = sessionStorage.getItem('language');

    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
        Language: language,
        'Language-Fallback': true
      }
    });

    return forward(operation);
  },
  onError(({ graphQLErrors, networkError }) => {
    if (
      graphQLErrors &&
      graphQLErrors.some(e => e.message === 'Insufficient Permissions')
    ) {
      // eslint-disable-next-line no-console
      console.error('Insufficient Permissions');
      authService.logout();
    }
    if (networkError) {
      console.error('networkError', networkError);
    }
  })
]);
export const client = new ApolloClient({
  cache,
  link: concat(authMiddleware, httpLink)
});
