import { AVAILABLE_LANGUAGES } from 'src/constants';
import {
  Box,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router';
import React, { useRef, useState } from 'react';

const useStyles = makeStyles(theme => ({
  badge: {
    borderRadius: 5,
    height: 10,
    marginRight: 5,
    marginTop: 10,
    width: 10
  },
  popover: {
    padding: theme.spacing(2),
    width: 320
  }
}));

function Settings() {
  const classes = useStyles();
  const local = useLocation();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = value => {
    sessionStorage.setItem('language', value);
    setOpen(false);
    if (
      local?.pathname &&
      local.pathname.includes('communities') &&
      local.pathname.includes('posts')
    ) {
      window.location.replace('/app/management/communities');
    } else {
      window.location.reload();
    }
  };

  return (
    <>
      <Tooltip title="Settings">
        <IconButton color="inherit" onClick={handleOpen} ref={ref}>
          <Typography>
            {sessionStorage.getItem('language').toUpperCase()}
          </Typography>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          Settings
        </Typography>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Language"
            name="language"
            onChange={event => handleSave(event.target.value)}
            select
            value={sessionStorage.getItem('language')}
            SelectProps={{ native: true }}
            variant="outlined"
          >
            {Object.keys(AVAILABLE_LANGUAGES).map(theme => (
              <option key={theme} value={theme}>
                {theme.toUpperCase()}
              </option>
            ))}
          </TextField>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
