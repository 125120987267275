import { gql } from 'apollo-boost';

export const ALL_RECIPES = gql`
  query getRecipeList($filter: RecipeFilter) {
    getRecipeList(filter: $filter) {
      id
      name
      image
      premium
      published
      onHomePage
      types {
        id
        name
      }
    }
  }
`;

export const ALL_RECIPES_PANEL = gql`
  query getRecipeListPanel($filter: RecipeFilter!) {
    getRecipeListPanel(filter: $filter) {
      recipes {
        id
        name
        premium
        published
        onHomePage
      }
      totalCount
    }
  }
`;

export const CREATE_RECIPE = gql`
  mutation createRecipe(
    $description: String
    $name: String!
    $image: String
    $time: String
    $tips: String
    $servings: String
    $video: String
    $videoBanner: String
    $videoTitle: String
    $videoDescription: String
    $videos: String
    $premium: Boolean
    $typeIds: [String]
    $steps: [RecipeStepInput]
    $ingredients: [RecipeIngredientInput]
    $onHomePage: Boolean
    $published: Boolean
    $goals: [String]
    $mealTypes: [String]
    $calories: Float
    $tlcProducts: String
  ) {
    createRecipe(
      description: $description
      name: $name
      image: $image
      time: $time
      tips: $tips
      video: $video
      videoBanner: $videoBanner
      videoTitle: $videoTitle
      videoDescription: $videoDescription
      videos: $videos
      servings: $servings
      premium: $premium
      steps: $steps
      typeIds: $typeIds
      ingredients: $ingredients
      onHomePage: $onHomePage
      published: $published
      goals: $goals
      mealTypes: $mealTypes
      calories: $calories
      tlcProducts: $tlcProducts
    ) {
      id
      name
      image
      description
      time
      tips
      servings
      premium
      onHomePage
      goals {
        id
        name
      }
      video
      videoBanner
      videoTitle
      videoDescription
      videos {
        id
        url
        thumbnail
        title
        description
      }
      published
      types {
        id
        name
      }
      ingredients {
        id
        name
        description
      }
      steps {
        id
        name
        description
      }
      mealTypes {
        id
        name
      }
      calories
      tlcProducts {
        id
        name
        image
        externalUrl
      }
    }
  }
`;

export const GET_RECIPE = gql`
  query getRecipe($id: String!) {
    getRecipe(id: $id) {
      id
      name
      image
      description
      time
      tips
      servings
      premium
      published
      goals {
        id
        name
      }
      onHomePage
      video
      videoBanner
      videoTitle
      videoDescription
      videos {
        id
        url
        thumbnail
        title
        description
      }
      types {
        id
        name
      }
      ingredients {
        id
        name
        description
      }
      steps {
        id
        name
        description
      }
      mealTypes {
        id
        name
      }
      calories
      tlcProducts {
        id
        name
        image
        externalUrl
      }
    }
  }
`;

export const UPDATE_RECIPE = gql`
  mutation updateRecipe(
    $id: String!
    $description: String
    $name: String!
    $image: String
    $time: String
    $tips: String
    $servings: String
    $video: String
    $videoBanner: String
    $videoTitle: String
    $videoDescription: String
    $videos: String
    $typeIds: [String]
    $premium: Boolean
    $onHomePage: Boolean
    $published: Boolean
    $steps: [RecipeStepInput]
    $ingredients: [RecipeIngredientInput]
    $goals: [String]
    $mealTypes: [String]
    $calories: Float
    $tlcProducts: String
  ) {
    updateRecipe(
      id: $id
      description: $description
      name: $name
      image: $image
      time: $time
      tips: $tips
      video: $video
      videoBanner: $videoBanner
      videoTitle: $videoTitle
      videoDescription: $videoDescription
      videos: $videos
      servings: $servings
      premium: $premium
      published: $published
      steps: $steps
      typeIds: $typeIds
      ingredients: $ingredients
      onHomePage: $onHomePage
      goals: $goals
      mealTypes: $mealTypes
      calories: $calories
      tlcProducts: $tlcProducts
    ) {
      id
      name
      image
      description
      time
      published
      tips
      servings
      premium
      onHomePage
      goals {
        id
        name
      }
      video
      videoBanner
      videoTitle
      videoDescription
      videos {
        id
        url
        thumbnail
        title
        description
      }
      types {
        id
        name
      }
      ingredients {
        id
        name
        description
      }
      steps {
        id
        name
        description
      }
      mealTypes {
        id
        name
      }
      calories
      tlcProducts {
        id
        name
        image
        externalUrl
      }
    }
  }
`;

export const UPDATE_RECIPE_TYPES = gql`
  mutation updateRecipeTypes($id: String!, $typeIds: [String]) {
    updateRecipeTypes(id: $id, typeIds: $typeIds) {
      id
      name
      types {
        id
        name
      }
    }
  }
`;

export const ALL_RECIPE_TYPES = gql`
  query {
    getRecipeTypeList {
      id
      description
      name
      image
      order
    }
  }
`;

export const ALL_RECIPE_TYPES_PANEL = gql`
  query getRecipeTypeListPanel($filter: RecipeTypeFilter!) {
    getRecipeTypeListPanel(filter: $filter) {
      recipeTypes {
        id
        name
        order
      }
      totalCount
    }
  }
`;

export const CREATE_RECIPE_TYPE = gql`
  mutation createRecipe(
    $name: String!
    $description: String
    $image: String
    $order: Int
  ) {
    createRecipeType(
      name: $name
      description: $description
      image: $image
      order: $order
    ) {
      id
      name
      description
      image
      order
    }
  }
`;

export const GET_RECIPE_TYPE = gql`
  query getRecipeType($id: String!) {
    getRecipeType(id: $id) {
      id
      name
      description
      image
      order
      recipes {
        id
        name
        types {
          id
          name
        }
      }
    }
  }
`;
export const UPDATE_RECIPE_TYPE = gql`
  mutation updateRecipeType(
    $id: String!
    $name: String!
    $description: String
    $image: String
    $order: Int
  ) {
    updateRecipeType(
      id: $id
      name: $name
      description: $description
      image: $image
      order: $order
    ) {
      id
      name
      description
      image
      order
    }
  }
`;

export const CREATE_ARTICLE = gql`
  mutation createArticle(
    $description: String
    $name: String!
    $image: String
    $onHomePage: Boolean
    $featured: Boolean
    $premium: Boolean
    $ownerId: String
    $video: String
    $videoBanner: String
    $videoTitle: String
    $videoDescription: String
    $videos: String
    $externalLink: String
    $healthyCoins: String
    $published: Boolean
    $time: String
    $goals: [String]
    $articleSound: String
    $shareImgSquare: String
    $shareImgRect: String
  ) {
    createArticle(
      description: $description
      name: $name
      image: $image
      premium: $premium
      featured: $featured
      onHomePage: $onHomePage
      video: $video
      videoBanner: $videoBanner
      videoTitle: $videoTitle
      videoDescription: $videoDescription
      videos: $videos
      ownerId: $ownerId
      externalLink: $externalLink
      healthyCoins: $healthyCoins
      published: $published
      time: $time
      goals: $goals
      articleSound: $articleSound
      shareImgSquare: $shareImgSquare
      shareImgRect: $shareImgRect
    ) {
      id
      name
      image
      description
      premium
      featured
      onHomePage
      healthyCoins
      externalLink
      video
      videoBanner
      videoTitle
      videoDescription
      videos {
        id
        url
        thumbnail
        title
        description
      }
      articleSound
      shareImgSquare
      shareImgRect
      published
      time
      goals {
        id
        name
      }
      owner {
        id
        firstName
        lastName
        profilePhoto
      }
    }
  }
`;

export const ALL_ARTICLES = gql`
  query getArticleList($filter: ArticleFilter) {
    getArticleList(filter: $filter) {
      id
      name
      image
      premium
      featured
      onHomePage
      published
      time
      goals {
        id
        name
        image
      }
      owner {
        id
        firstName
        lastName
        profilePhoto
      }
    }
  }
`;

export const ALL_ARTICLES_PANEL = gql`
  query getArticleListPanel($filter: ArticleFilter!) {
    getArticleListPanel(filter: $filter) {
      articles {
        id
        name
        premium
        featured
        onHomePage
        published
      }
      totalCount
    }
  }
`;

export const GET_ARTICLE = gql`
  query getArticle($id: String!) {
    getArticle(id: $id) {
      id
      name
      image
      description
      premium
      featured
      onHomePage
      healthyCoins
      published
      video
      videoBanner
      videoTitle
      videoDescription
      videos {
        id
        url
        thumbnail
        title
        description
      }
      articleSound
      shareImgSquare
      shareImgRect
      externalLink
      time
      goals {
        id
        name
      }
      owner {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation updateArticle(
    $id: String!
    $name: String
    $description: String
    $image: String
    $onHomePage: Boolean
    $featured: Boolean
    $premium: Boolean
    $ownerId: String
    $externalLink: String
    $healthyCoins: String
    $video: String
    $videoBanner: String
    $videoTitle: String
    $videoDescription: String
    $videos: String
    $time: String
    $published: Boolean
    $goals: [String]
    $articleSound: String
    $shareImgSquare: String
    $shareImgRect: String
  ) {
    updateArticle(
      id: $id
      name: $name
      image: $image
      description: $description
      premium: $premium
      featured: $featured
      onHomePage: $onHomePage
      video: $video
      videoBanner: $videoBanner
      videoTitle: $videoTitle
      videoDescription: $videoDescription
      videos: $videos
      ownerId: $ownerId
      externalLink: $externalLink
      healthyCoins: $healthyCoins
      published: $published
      time: $time
      goals: $goals
      articleSound: $articleSound
      shareImgSquare: $shareImgSquare
      shareImgRect: $shareImgRect
    ) {
      id
      name
      image
      description
      premium
      featured
      onHomePage
      healthyCoins
      externalLink
      time
      published
      video
      videoBanner
      videoTitle
      videoDescription
      videos {
        id
        url
        thumbnail
        title
        description
      }
      articleSound
      shareImgSquare
      shareImgRect
      externalLink
      goals {
        id
        name
      }
      owner {
        id
        firstName
        lastName
        profilePhoto
      }
    }
  }
`;

export const GET_PLAYLIST_CATEGORIES_PANEL = gql`
  query getPlayListCategoriesPanel($filter: PlayListCategoryFilter!) {
    getPlayListCategoriesPanel(filter: $filter) {
      playListCategories {
        id
        name
      }
      totalCount
    }
  }
`;

export const GET_PLAYLIST_CATEGORIES = gql`
  query getPlayListCategories {
    getPlayListCategories {
      id
      name
    }
  }
`;

export const CREATE_PLAYLIST_CATEGORY = gql`
  mutation createPlayListCategory($name: String!) {
    createPlayListCategory(name: $name) {
      id
      name
    }
  }
`;

export const UPDATE_PLAYLIST_CATEGORY = gql`
  mutation updatePlayListCategory($id: String!, $name: String!) {
    updatePlayListCategory(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const GET_PLAYLISTS = gql`
  query getPlayListsPanel($filter: PlayListFilter!) {
    getPlayListsPanel(filter: $filter) {
      playLists {
        id
        name
        thumbnail
        published
      }
      totalCount
    }
  }
`;

export const CREATE_PLAYLIST = gql`
  mutation createPlayList(
    $name: String!
    $thumbnail: String!
    $published: Boolean!
    $videos: String!
    $categoryIds: [String]!
  ) {
    createPlayList(
      name: $name
      thumbnail: $thumbnail
      published: $published
      videos: $videos
      categoryIds: $categoryIds
    ) {
      id
      name
      thumbnail
      published
    }
  }
`;

export const UPDATE_PLAYLIST = gql`
  mutation updatePlayList(
    $id: String!
    $name: String!
    $thumbnail: String!
    $published: Boolean!
    $videos: String!
    $categoryIds: [String]!
  ) {
    updatePlayList(
      id: $id
      name: $name
      thumbnail: $thumbnail
      published: $published
      videos: $videos
      categoryIds: $categoryIds
    ) {
      id
      name
      thumbnail
      published
    }
  }
`;

export const GET_PLAYLIST = gql`
  query getPlayList($id: String!) {
    getPlayList(id: $id) {
      id
      name
      thumbnail
      published
      categories {
        id
        name
      }
      videos {
        id
        url
        thumbnail
        title
        description
      }
    }
  }
`;

export const ALL_RECIPE_MEAL_TYPES = gql`
  query getRecipeMealTypeList {
    getRecipeMealTypeList {
      id
      name
    }
  }
`;

export const GET_RECIPE_MEAL_TYPE_LIST = gql`
  query getRecipeMealTypeListPanel($filter: RecipeMealTypeFilter!) {
    getRecipeMealTypeListPanel(filter: $filter) {
      recipeMealTypes {
        id
        name
      }
      totalCount
    }
  }
`;

export const CREATE_RECIPE_MEAL_TYPE = gql`
  mutation createRecipeMealType($name: String!) {
    createRecipeMealType(name: $name) {
      id
      name
    }
  }
`;

export const UPDATE_RECIPE_MEAL_TYPE = gql`
  mutation updateRecipeMealType($id: String!, $name: String!) {
    updateRecipeMealType(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const USER_FEEDBACKS = gql`
  query getUserFeedbacks($filter: UserFeedbackFilter!) {
    getUserFeedbacks(filter: $filter) {
      userFeedbacks {
        id
        title
        user {
          email
        }
        feedback
        createdAt
      }
      totalCount
    }
  }
`;

export const ALL_EVENTS_PANEL = gql`
  query getEventListPanel($filter: Filter) {
    getEventListPanel(filter: $filter) {
      events {
        id
        name
        description
        externalUrl
        published
        published
        goals {
          id
          name
        }
      }
      totalCount
    }
  }
`;

export const ALL_EVENTS = gql`
  query getEventList {
    getEventList {
      id
      name
      description
      externalUrl
      published
      published
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent(
    $name: String!
    $description: String
    $externalUrl: String
    $published: Boolean
    $goalIds: [ID!]
  ) {
    createEvent(
      name: $name
      description: $description
      externalUrl: $externalUrl
      published: $published
      goalIds: $goalIds
    ) {
      id
      name
      description
      externalUrl
      published
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent(
    $id: ID!
    $name: String!
    $description: String
    $externalUrl: String
    $published: Boolean
    $goalIds: [ID!]
  ) {
    updateEvent(
      id: $id
      name: $name
      description: $description
      externalUrl: $externalUrl
      published: $published
      goalIds: $goalIds
    ) {
      id
      name
      description
      externalUrl
      published
    }
  }
`;
