import { gql } from 'apollo-boost';

export const PROMO_CODE_LIST = gql`
  query getPromoCodeList($filter: Filter!) {
    getPromoCodeList(filter: $filter) {
      promoCodes {
        code
        name
        type
        count
      }
      totalCount
    }
  }
`;

export const PROMO_CODE = gql`
  query getPromoCode($code: String!, $filter: Filter!) {
    getPromoCode(code: $code) {
      promoCode {
        code
        count
        usedCount
        program {
          id
          name
        }
      }
      users(filter: $filter) {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const GENERATE_PROMO_CODE = gql`
  mutation generateProgramCode($programId: ID!, $code: String!, $count: Int!) {
    generateProgramCode(programId: $programId, code: $code, count: $count)
  }
`;
