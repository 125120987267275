import './index.css';
import 'nprogress/nprogress.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import * as Sentry from '@sentry/react';
import * as serviceWorker from 'src/serviceWorker';
import { ApolloProvider } from '@apollo/react-hooks';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { SettingsProvider } from 'src/context/SettingsContext';
import { client } from './graphql/client';
import { configureStore } from 'src/store';
import { enableES5 } from 'immer';
import { restoreSettings } from 'src/utils/settings';
import App from 'src/App';
import React from 'react';
import ReactDOM from 'react-dom';

Sentry.init({
  dsn:
    'https://4dba961922bc489a8cd1eea4120c13df@o171922.ingest.sentry.io/5824409',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

enableES5();

const store = configureStore();
const settings = restoreSettings();

let existingUserData = localStorage.getItem('loggedInUser');

if (existingUserData) {
  existingUserData = JSON.parse(existingUserData);
  Sentry.setUser({ email: existingUserData.email });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <SettingsProvider settings={settings}>
          <App />
        </SettingsProvider>
      </Provider>
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

serviceWorker.unregister();
