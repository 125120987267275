import { gql } from 'apollo-boost';

export const AUTHENTICATE_USER = gql`
  mutation authenticateUser($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      token
      user {
        id
        email
        firstName
        lastName
        role
      }
    }
  }
`;

export const ALL_USERS = gql`
  query getUserList {
    getUserList {
      id
      firstName
      lastName
      email
      profilePhoto
      createdAt
      fcmIds
      status
      role
      type
    }
  }
`;

export const ALL_USERS_PANEL = gql`
  query getUserListPanel($filter: UserFilter!) {
    getUserListPanel(filter: $filter) {
      users {
        id
        firstName
        lastName
        email
        profilePhoto
        createdAt
        fcmIds
        status
        role
        type
      }
      total
    }
  }
`;

export const DEACTIVATED_USERS = gql`
  query getDeactivatedUserList($filter: UserFilter!) {
    getDeactivatedUserList(filter: $filter) {
      users {
        id
        firstName
        lastName
        email
        utm_source
      }
      total
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: String!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      profilePhoto
      createdAt
      fcmIds
      status
      role
      type
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: String!
    $firstName: String
    $lastName: String
    $profilePhoto: String
    $role: UserRole
    $type: UserType
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      profilePhoto: $profilePhoto
      role: $role
      type: $type
    ) {
      id
      firstName
      lastName
      email
      profilePhoto
      createdAt
      fcmIds
      status
    }
  }
`;
