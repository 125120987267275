/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import AnchorIcon from 'remixicon-react/AnchorFillIcon';
import BarChartIcon from 'remixicon-react/BarChartGroupedLineIcon';
import CommunityIcon from 'remixicon-react/TeamFillIcon';
import CompassLineIcon from 'remixicon-react/Compass3LineIcon';
import CorporationIcon from 'remixicon-react/Building2FillIcon';
import CouponIcon from 'remixicon-react/CouponLineIcon';
import EventIcon from 'remixicon-react/CalendarEventFillIcon';
import ExchangeLine from 'remixicon-react/ExchangeDollarLineIcon';
import FAQLine from 'remixicon-react/QuestionAnswerLineIcon';
import FeedbackIcon from 'remixicon-react/FeedbackLineIcon';
import FlaskLineIcon from 'remixicon-react/FlaskLineIcon';
import Focus2LineIcon from 'remixicon-react/Focus2LineIcon';
import Focus3LineIcon from 'remixicon-react/Focus3LineIcon';
import FolderIcon from 'remixicon-react/FoldersLineIcon';
import GitLineIcon from 'remixicon-react/GitRepositoryLineIcon';
import GroupLineIcon from 'remixicon-react/GroupLineIcon';
import HeartPulseIcon from 'remixicon-react/HeartPulseLineIcon';
import InformationIcon from 'remixicon-react/InformationLineIcon';
import LeaderBoardIcon from 'remixicon-react/ContactsLineIcon';
import LineChartIcon from 'remixicon-react/LineChartLineIcon';
import Logo from 'src/components/Logo';
import MarkupPenIcon from 'remixicon-react/MarkPenLineIcon';
import MealTypeIcon from 'remixicon-react/Restaurant2FillIcon';
import MedalIcon from 'remixicon-react/Medal2FillIcon';
import NavItem from './NavItem';
import NotificationIcon from 'remixicon-react/Notification2LineIcon';
import OpenArmIcon from 'remixicon-react/OpenArmLineIcon';
import PaymentIcon from 'remixicon-react/ShoppingBasketLineIcon';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PieChartIcon from 'remixicon-react/PieChart2LineIcon';
import PlayListLineIcon from 'remixicon-react/PlayList2LineIcon';
import ProgramIcon from 'remixicon-react/OrganizationChartIcon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SurveyIcon from 'remixicon-react/SurveyLineIcon';
import TerminalIcon from 'remixicon-react/TerminalWindowLineIcon';
import TubeLineIcon from 'remixicon-react/TestTubeLineIcon';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      exact: false,
      path: item.href
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.items,
          pathname
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  avatar: {
    cursor: 'pointer',
    height: 64,
    width: 64
  },
  desktopDrawer: {
    height: 'calc(100% - 64px)',
    top: 64,
    width: 256
  },
  mobileDrawer: {
    width: 256
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => {
    return state.account;
  });
  const { user: loggedInUser } = useSelector((state) => state.account);

  const managementMenu = [
    {
      icon: GroupLineIcon,
      items: [
        {
          href: '/app/management/users',
          title: 'Details'
        },
        {
          href: '/app/management/users-deactivated',
          title: 'Deactivated Users'
        }
      ],
      title: 'Members'
    },
    {
      icon: CorporationIcon,
      items: [
        {
          href: '/app/management/corporations',
          title: 'Details'
        },
        {
          href: '/app/management/corporations/departments',
          title: 'Departments'
        }
      ],
      title: 'Corporations'
    },
    {
      href: '/app/management/campaigns',
      icon: ExchangeLine,
      title: 'Campaigns'
    },
    {
      href: '/app/management/communities',
      icon: CommunityIcon,
      items: [
        {
          href: '/app/management/communities',
          title: 'Details'
        },
        {
          href: '/app/management/communities/latest-comments',
          title: 'Latest Comments'
        }
      ],
      title: 'Communities'
    },
    {
      href: '/app/management/events',
      icon: EventIcon,
      title: 'Events/Workshops'
    },
    {
      href: '/app/management/products',
      icon: PaymentIcon,
      title: 'In App Products'
    },
    {
      href: '/app/management/coupon-codes',
      icon: CouponIcon,
      title: 'Coupon Codes'
    },
    {
      href: '/app/management/programs',
      icon: ProgramIcon,
      title: 'Programs'
    },
    {
      href: '/app/management/challenge',
      icon: Focus2LineIcon,
      title: 'Challenges'
    },
    {
      href: '/app/management/mini-challenge',
      icon: Focus3LineIcon,
      title: 'Mini Challenges'
    },
    {
      href: '/app/management/goal',
      icon: OpenArmIcon,
      title: 'Goals'
    },
    {
      href: '/app/management/improvement-area',
      icon: CompassLineIcon,
      title: 'Improvement Areas'
    },
    {
      href: '/app/management/badges',
      icon: MedalIcon,
      title: 'Badges'
    },
    {
      href: '/app/management/article',
      icon: GitLineIcon,
      title: 'Articles'
    },
    {
      href: '/app/management/recipe',
      icon: FlaskLineIcon,
      title: 'Recipes'
    },
    {
      href: '/app/management/recipe-type',
      icon: TubeLineIcon,
      title: 'Recipe Types'
    },
    {
      href: '/app/management/meal-types',
      icon: MealTypeIcon,
      title: 'Recipe Meal Types'
    },
    {
      href: '/app/management/onboard',
      icon: AnchorIcon,
      title: 'Onboard'
    },
    {
      href: '/app/management/onboard-surveys',
      icon: SurveyIcon,
      title: 'Onboard Survey'
    },
    {
      href: '/app/management/health-problem',
      icon: HeartPulseIcon,
      title: 'Health Problems'
    },
    {
      href: '/app/management/playlists',
      icon: PlayListLineIcon,
      items: [
        {
          href: '/app/management/playlists',
          title: 'Details'
        },
        {
          href: '/app/management/playlists/categories',
          title: 'Categories'
        }
      ],
      title: 'Playlists'
    },
    {
      href: '/app/management/informations',
      icon: InformationIcon,
      title: 'Informations'
    },
    {
      href: '/app/management/general-faq',
      icon: FAQLine,
      title: 'General FAQ'
    }
  ];

  if (loggedInUser.role === 'SuperAdmin') {
    managementMenu.push({
      href: '/app/management/user-input',
      icon: MarkupPenIcon,
      title: 'User Inputs'
    });

    managementMenu.push({
      href: '/app/management/send-notification',
      icon: NotificationIcon,
      title: 'Send Notification'
    });

    managementMenu.push({
      href: '/app/management/static-config',
      icon: FolderIcon,
      title: 'Static Config'
    });

    managementMenu.push({
      href: '/app/management/app-config',
      icon: TerminalIcon,
      title: 'App Config'
    });
  }

  const navConfig = [
    {
      items: [
        {
          href: '/app/reports/overview',
          icon: PieChartIcon,
          title: 'Overview'
        },
        {
          href: '/app/reports/progression-logs',
          icon: LineChartIcon,
          title: 'Challenge Progressions'
        },
        {
          href: '/app/reports/action-logs',
          icon: BarChartIcon,
          title: 'Action Logs'
        },
        {
          href: '/app/reports/leaderboard',
          icon: LeaderBoardIcon,
          title: 'Leaderboard'
        },
        {
          href: '/app/reports/userfeedbacks',
          icon: FeedbackIcon,
          title: 'User Feedbacks'
        }
      ],
      subheader: 'Reports'
    },
    {
      items: managementMenu,
      subheader: 'Management'
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="#">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.profileImage}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="#"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstName} ${user.lastName}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
