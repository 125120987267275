import * as Sentry from '@sentry/react';
import { AUTHENTICATE_USER } from '../graphql/queries';
import { client } from '../graphql/client';
import axios from 'src/utils/axios';
import jwtDecode from 'jwt-decode';

class AuthService {
  // setAxiosInterceptors = ({ onLogout }) => {
  //   axios.interceptors.response.use(
  //     (response) => response,
  //     (error) => {
  //       if (error.response && error.response.status === 401) {
  //         this.setSession(null);
  //
  //         if (onLogout) {
  //           onLogout();
  //         }
  //       }
  //
  //       return Promise.reject(error);
  //     }
  //   );
  // };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: AUTHENTICATE_USER,
          variables: { email, password }
        })
        .then(({ data }) => {
          const reply = data.authenticate;
          this.setSession(reply.token);
          const userObject = { ...reply.user, token: reply.token };
          localStorage.setItem('loggedInUser', JSON.stringify(userObject));

          resolve(userObject);
        })
        .catch(error => reject(error.graphQLErrors[0].message));
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      const existingUserData = JSON.parse(localStorage.getItem('loggedInUser'));
      Sentry.setUser({ email: existingUserData.email });
      resolve(existingUserData);
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('loggedInUser');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
