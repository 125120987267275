import { gql } from '@apollo/client';

export const GET_APP_CONFIG = gql`
  query getAppConfig {
    getAppConfig {
      appName
    }
  }
`;

export const UPDATE_APP_CONFIG = gql`
  mutation updateAppConfig($appName: String) {
    updateAppConfig(appName: $appName) {
      appName
    }
  }
`;

export const ALL_STATIC_CONFIGS = gql`
  query getStaticConfigs {
    getStaticConfigs {
      id
      configVersion
      status
    }
  }
`;

export const CREATE_STATIC_CONFIG = gql`
  mutation createStaticConfig($configVersion: String!) {
    createStaticConfig(configVersion: $configVersion) {
      id
      configVersion
    }
  }
`;

export const UPDATE_STATIC_CONFIG = gql`
  mutation updateStaticConfig($id: String!, $status: String!) {
    updateStaticConfig(id: $id, status: $status) {
      id
      configVersion
    }
  }
`;
