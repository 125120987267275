import { gql } from 'apollo-boost';

export const CREATE_NOTIFICATION = gql`
  mutation createNotification(
    $name: String
    $placeholder: String
    $inputId: String
    $type: String
    $question: String
  ) {
    createNotification(
      name: $name
      placeholder: $placeholder
      inputId: $inputId
      type: $type
      question: $question
    ) {
      id
      name
      inputId
      type
      question
      placeholder
    }
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation sendNotification(
    $userId: String!
    $title: String!
    $body: String!
    $challengeId: String
    $eventId: String
    $miniChallengeId: String
    $programId: String
    $articleId: String
    $recipeId: String
    $iconType: NotificationIconTypeEnum
    $sound: String
  ) {
    sendNotification(
      userId: $userId
      title: $title
      body: $body
      challengeId: $challengeId
      eventId: $eventId
      miniChallengeId: $miniChallengeId
      programId: $programId
      articleId: $articleId
      recipeId: $recipeId
      iconType: $iconType
      sound: $sound
    )
  }
`;
