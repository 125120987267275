import { gql } from 'apollo-boost';

export const PRODUCT_LIST = gql`
  query getProductList {
    getProductList {
      id
      packageId
      createdAt
    }
  }
`;

export const PRODUCT_LIST_PANEL = gql`
  query getProductListPanel($filter: Filter) {
    getProductListPanel(filter: $filter) {
      products {
        id
        packageId
        createdAt
      }
      totalCount
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProduct($id: ID!) {
    getProduct(id: $id) {
      id
      packageId
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($packageId: String!) {
    createProduct(packageId: $packageId) {
      id
      packageId
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: ID!, $packageId: String!) {
    updateProduct(id: $id, packageId: $packageId) {
      id
      packageId
    }
  }
`;
