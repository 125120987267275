import { gql } from 'apollo-boost';

export const ALL_PROGRAMS_PANEL = gql`
  query getProgramListPanel($filter: ProgramsFilter!) {
    getProgramListPanel(filter: $filter) {
      programs {
        id
        name
        premium
        published
        image
        imageSmall
      }
      totalCount
    }
  }
`;

export const GET_PROGRAMS = gql`
  query getPrograms {
    getPrograms {
      id
      name
      image
      published
      product {
        id
      }
    }
  }
`;

export const GET_PROGRAM = gql`
  query getProgram($id: String!) {
    getProgram(id: $id) {
      id
      benefits {
        id
        name
        description
        image
        goals {
          id
          name
        }
        article {
          id
          name
        }
        recipe {
          id
          name
        }
        action {
          id
          name
          type {
            id
            name
          }
          article {
            id
            name
          }
          recipe {
            id
            name
          }
          redirect {
            url
            text
          }
        }
      }
      content
      description
      faqs
      joinProgramDescription
      name
      shortDescription
      premium
      published
      featured
      image
      imageSmall
      notifications {
        id
        name
        userInput {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        userInputs {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        message
        duration
        repeating
        absoluteTime
        type {
          id
          name
        }
        sound
      }
      programSchedules {
        id
        miniChallenge {
          id
          name
          bannerImage
        }
        day
      }
      nextType
      nextId
      shareImageSquare
      taskGroups {
        id
        name
        description
        miniChallenges {
          id
          name
          bannerImage
        }
      }
      isSeries
      preDetox
      postDetox
      blockedContentIds
      isProduct
      product {
        id
        packageId
      }
    }
  }
`;

export const CREATE_PROGRAM = gql`
  mutation createProgram(
    $benefits: String
    $content: String
    $description: String
    $faqs: [FAQInput]
    $image: String!
    $imageSmall: String!
    $name: String!
    $joinProgramDescription: String
    $notifications: String
    $premium: Boolean
    $published: Boolean!
    $featured: Boolean
    $shortDescription: String
    $programSchedules: [ProgramScheduleInput]
    $nextType: NextType
    $nextId: ID
    $shareImageSquare: String
    $taskGroups: String
    $isSeries: Boolean
    $preDetox: Boolean
    $postDetox: Boolean
    $blockedContentIds: [String]
    $isProduct: Boolean
    $productId: String
  ) {
    createProgram(
      benefits: $benefits
      content: $content
      description: $description
      faqs: $faqs
      image: $image
      imageSmall: $imageSmall
      name: $name
      joinProgramDescription: $joinProgramDescription
      notifications: $notifications
      premium: $premium
      published: $published
      featured: $featured
      shortDescription: $shortDescription
      programSchedules: $programSchedules
      nextType: $nextType
      nextId: $nextId
      shareImageSquare: $shareImageSquare
      taskGroups: $taskGroups
      isSeries: $isSeries
      preDetox: $preDetox
      postDetox: $postDetox
      blockedContentIds: $blockedContentIds
      isProduct: $isProduct
      productId: $productId
    ) {
      id
      benefits {
        id
        name
        description
        image
        goals {
          id
          name
        }
        article {
          id
          name
        }
        recipe {
          id
          name
        }
        action {
          id
          name
          type {
            id
            name
          }
          article {
            id
            name
          }
          recipe {
            id
            name
          }
          redirect {
            url
            text
          }
        }
      }
      faqs
      joinProgramDescription
      name
      shortDescription
      description
      content
      premium
      published
      featured
      image
      imageSmall
      notifications {
        id
        name
      }
      programSchedules {
        id
        miniChallenge {
          id
          name
        }
        day
      }
      nextId
      nextType
      shareImageSquare
      taskGroups {
        id
        name
        description
        miniChallenges {
          id
          name
          bannerImage
        }
      }
      isSeries
      preDetox
      postDetox
      blockedContentIds
      isProduct
      product {
        id
        packageId
      }
    }
  }
`;

export const EDIT_PROGRAM = gql`
  mutation editProgram(
    $id: String!
    $benefits: String
    $content: String
    $description: String
    $faqs: [FAQInput]
    $image: String!
    $imageSmall: String!
    $name: String!
    $joinProgramDescription: String
    $notifications: String
    $premium: Boolean
    $published: Boolean!
    $featured: Boolean
    $shortDescription: String
    $programSchedules: [ProgramScheduleInput]
    $nextType: NextType
    $nextId: ID
    $shareImageSquare: String
    $taskGroups: String
    $isSeries: Boolean
    $preDetox: Boolean
    $postDetox: Boolean
    $blockedContentIds: [String]
    $isProduct: Boolean
    $productId: String
  ) {
    editProgram(
      id: $id
      benefits: $benefits
      content: $content
      description: $description
      faqs: $faqs
      image: $image
      imageSmall: $imageSmall
      name: $name
      joinProgramDescription: $joinProgramDescription
      notifications: $notifications
      premium: $premium
      published: $published
      featured: $featured
      shortDescription: $shortDescription
      programSchedules: $programSchedules
      nextType: $nextType
      nextId: $nextId
      shareImageSquare: $shareImageSquare
      taskGroups: $taskGroups
      isSeries: $isSeries
      preDetox: $preDetox
      postDetox: $postDetox
      blockedContentIds: $blockedContentIds
      isProduct: $isProduct
      productId: $productId
    ) {
      id
      benefits {
        id
        name
        description
        image
        goals {
          id
          name
        }
        article {
          id
          name
        }
        recipe {
          id
          name
        }
        action {
          id
          name
          type {
            id
            name
          }
          article {
            id
            name
          }
          recipe {
            id
            name
          }
          redirect {
            url
            text
          }
        }
      }
      faqs
      joinProgramDescription
      name
      shortDescription
      description
      content
      premium
      published
      featured
      image
      imageSmall
      notifications {
        id
        name
      }
      programSchedules {
        id
        miniChallenge {
          id
          name
        }
        day
      }
      nextId
      nextType
      shareImageSquare
      taskGroups {
        id
        name
        description
        miniChallenges {
          id
          name
          bannerImage
        }
      }
      isSeries
      preDetox
      postDetox
      blockedContentIds
      isProduct
      product {
        id
        packageId
      }
    }
  }
`;
