import { gql } from 'apollo-boost';

export const CREATE_ONBOARD = gql`
  mutation createOnboard(
    $description: String
    $name: String!
    $image: String
    $order: Int
  ) {
    createOnboard(
      description: $description
      name: $name
      image: $image
      order: $order
    ) {
      id
      name
      description
      image
      order
    }
  }
`;

export const UPDATE_ONBOARD = gql`
  mutation updateOnboard(
    $id: String!
    $description: String
    $name: String!
    $image: String
    $order: Int
  ) {
    updateOnboard(
      id: $id
      description: $description
      name: $name
      image: $image
      order: $order
    ) {
      id
      name
      description
      image
      order
    }
  }
`;

export const GET_ONBOARD = gql`
  query getOnboard($id: String!) {
    getOnboard(id: $id) {
      id
      name
      description
      image
      order
    }
  }
`;

export const ALL_ONBOARDS = gql`
  {
    getOnboardList {
      id
      name
      description
      image
      order
    }
  }
`;

export const ALL_ONBOARDS_PANEL = gql`
  query getOnboardList($filter: OnboardFilter!) {
    getOnboardListPanel(filter: $filter) {
      onboards {
        id
        name
        image
        order
      }
      totalCount
    }
  }
`;

export const ALL_USER_INPUTS = gql`
  query getUserInputList($type: UserInputType) {
    getUserInputList(type: $type) {
      id
      name
      inputId
      type
      question
      defaultValue
      placeholder
      description
    }
  }
`;

export const ALL_USER_INPUTS_PANEL = gql`
  query getUserInputListPanel($filter: UserInputFilter!) {
    getUserInputListPanel(filter: $filter) {
      userInputs {
        id
        name
        type
      }
      totalCount
    }
  }
`;

export const GET_USER_INPUT = gql`
  query getUserInput($id: String!) {
    getUserInput(id: $id) {
      id
      name
      inputId
      type
      question
      placeholder
      defaultValue
      description
    }
  }
`;

export const CREATE_USER_INPUT = gql`
  mutation createUserInput(
    $name: String
    $placeholder: String
    $inputId: String
    $type: String
    $question: String
    $defaultValue: String
    $description: String
  ) {
    createUserInput(
      name: $name
      placeholder: $placeholder
      inputId: $inputId
      type: $type
      question: $question
      defaultValue: $defaultValue
      description: $description
    ) {
      id
      name
      inputId
      defaultValue
      type
      question
      placeholder
      description
    }
  }
`;

export const UPDATE_USER_INPUT = gql`
  mutation updateUserInput(
    $id: String!
    $name: String
    $placeholder: String
    $inputId: String
    $type: String
    $defaultValue: String
    $question: String
    $description: String
  ) {
    updateUserInput(
      id: $id
      name: $name
      placeholder: $placeholder
      inputId: $inputId
      type: $type
      question: $question
      defaultValue: $defaultValue
      description: $description
    ) {
      id
      name
      inputId
      defaultValue
      type
      question
      placeholder
      description
    }
  }
`;

export const ALL_FAQS = gql`
  query getFAQList {
    getFAQList {
      id
      question
      answer
    }
  }
`;

export const GET_FAQ = gql`
  query getFAQ($id: String!) {
    getFAQ(id: $id) {
      id
      question
      answer
    }
  }
`;

export const CREATE_FAQ = gql`
  mutation createFAQ($question: String!, $answer: String!) {
    createFAQ(question: $question, answer: $answer) {
      id
      question
      answer
    }
  }
`;

export const UPDATE_FAQ = gql`
  mutation updateFAQ($id: String!, $question: String!, $answer: String!) {
    updateFAQ(id: $id, question: $question, answer: $answer) {
      id
      question
      answer
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation deleteFAQ($id: String!) {
    deleteFAQ(id: $id)
  }
`;

export const DELETE_ENTITY = gql`
  mutation deleteEntities($ids: [String!], $modelName: String!) {
    deleteEntities(ids: $ids, modelName: $modelName)
  }
`;

export const GET_ONBOARD_SURVEY = gql`
  query getOnboardSurvey($id: String!) {
    getOnboardSurvey(id: $id) {
      id
      title
      backgroundImage
      backgroundColor
      order
      question {
        id
        question
        multiSelect
        type
        media
        answers {
          id
          answer
        }
      }
    }
  }
`;

export const CREATE_ONBOARD_SURVEY = gql`
  mutation createOnboardSurvey(
    $title: String!
    $backgroundImage: String!
    $backgroundColor: String!
    $order: Int!
    $multiSelect: Boolean!
    $question: SurveyQuestionInput!
    $answers: [SurveyAnswerInput!]!
  ) {
    createOnboardSurvey(
      title: $title
      backgroundImage: $backgroundImage
      backgroundColor: $backgroundColor
      order: $order
      multiSelect: $multiSelect
      question: $question
      answers: $answers
    ) {
      id
      title
      backgroundImage
      backgroundColor
    }
  }
`;

export const UPDATE_ONBOARD_SURVEY = gql`
  mutation updateOnboardSurvey(
    $id: String!
    $title: String!
    $backgroundImage: String!
    $backgroundColor: String!
    $order: Int!
    $multiSelect: Boolean!
    $question: SurveyQuestionInput!
    $answers: [SurveyAnswerInput!]!
  ) {
    updateOnboardSurvey(
      id: $id
      title: $title
      backgroundImage: $backgroundImage
      backgroundColor: $backgroundColor
      order: $order
      multiSelect: $multiSelect
      question: $question
      answers: $answers
    ) {
      id
      title
      backgroundImage
      backgroundColor
    }
  }
`;

export const ALL_ONBOARD_SURVEYS = gql`
  query getOnboardSurveysPanel {
    getOnboardSurveysPanel {
      onboardSurveys {
        id
        title
        backgroundImage
        backgroundColor
        order
      }
      totalCount
    }
  }
`;
