/* eslint-disable react/no-array-index-key */
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import React, { Fragment, Suspense, lazy } from 'react';

const routesConfig = [
  {
    component: () => <Redirect to="/login" />,
    exact: true,
    path: '/'
  },
  {
    component: lazy(() => import('src/views/pages/Error404View')),
    exact: true,
    path: '/404'
  },
  {
    component: lazy(() => import('src/views/auth/LoginView')),
    exact: true,
    guard: GuestGuard,
    path: '/login'
  },

  {
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app',
    routes: [
      //* REPORTS
      {
        component: () => <Redirect to="/app/reports/overview" />,
        exact: true,
        path: '/app'
      },
      {
        component: () => <Redirect to="/app/reports/overview" />,
        exact: true,
        path: '/app/reports'
      },
      {
        component: lazy(() => import('src/views/reports/OverviewView')),
        exact: true,
        path: '/app/reports/overview'
      },
      {
        component: lazy(() =>
          import('src/views/reports/ChallengeProgressionLogsView')
        ),
        exact: true,
        path: '/app/reports/progression-logs'
      },
      {
        component: lazy(() => import('src/views/reports/UserActionLogsView')),
        exact: true,
        path: '/app/reports/action-logs'
      },
      // Leaderboard
      {
        component: lazy(() => import('src/views/reports/LeaderBoardView')),
        exact: true,
        path: '/app/reports/leaderboard'
      },
      // User feedbacks
      {
        component: lazy(() => import('src/views/reports/FeedbacksView')),
        exact: true,
        path: '/app/reports/userfeedbacks'
      },

      //* MANAGEMENT
      {
        component: () => <Redirect to="/app/management/users" />,
        exact: true,
        path: '/app/management'
      },
      // Members
      {
        component: lazy(() =>
          import('src/views/management/member/MemberDetailsView')
        ),
        exact: true,
        path: '/app/management/users/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/member/MemberEditView')
        ),
        exact: true,
        path: '/app/management/users/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/member/MemberListView')
        ),
        exact: true,
        path: '/app/management/users'
      },
      {
        component: lazy(() =>
          import('src/views/management/member/MemberDListView')
        ),
        exact: true,
        path: '/app/management/users-deactivated'
      },
      // Corporations
      {
        component: lazy(() =>
          import('src/views/management/corporation/CorporateListView')
        ),
        exact: true,
        path: '/app/management/corporations'
      },
      {
        component: lazy(() =>
          import('src/views/management/corporation/CorporateCUView')
        ),
        exact: true,
        path: '/app/management/corporations/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/corporation/CorporateCUView')
        ),
        exact: true,
        path: '/app/management/corporations/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/corporation/DepartmentListView')
        ),
        exact: true,
        path: '/app/management/corporations/departments'
      },
      // Campaign
      {
        component: lazy(() =>
          import('src/views/management/campaign/CampaignListView')
        ),
        exact: true,
        path: '/app/management/campaigns'
      },
      {
        component: lazy(() =>
          import('src/views/management/campaign/CampaignCUView')
        ),
        exact: true,
        path: '/app/management/campaigns/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/campaign/CampaignCUView')
        ),
        exact: true,
        path: '/app/management/campaigns/edit/:id'
      },
      // COMMUNITY
      // Community Board
      {
        component: lazy(() => import('src/views/management/community')),
        exact: true,
        path: '/app/management/communities'
      },
      // Community Post
      {
        component: lazy(() =>
          import('src/views/management/community/CommunityPostListView')
        ),
        exact: true,
        path: '/app/management/communities/:id/posts'
      },
      {
        component: lazy(() =>
          import('src/views/management/community/CommunityPostCUView')
        ),
        exact: true,
        path: '/app/management/communities/:id/posts/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/community/CommunityPostCUView')
        ),
        exact: true,
        path: '/app/management/communities/:id/posts/:postId/edit'
      },
      // Community Comment
      {
        component: lazy(() =>
          import('src/views/management/community/CommunityComments')
        ),
        exact: true,
        path: '/app/management/communities/latest-comments'
      },
      // Events
      {
        component: lazy(() => import('src/views/management/event')),
        exact: true,
        path: '/app/management/events'
      },
      // In App Products
      {
        component: lazy(() => import('src/views/management/product')),
        exact: true,
        path: '/app/management/products'
      },
      // Coupon Codes
      {
        component: lazy(() =>
          import('src/views/management/couponCodes/CouponCodeCUView')
        ),
        exact: true,
        path: '/app/management/coupon-codes/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/couponCodes/CouponCodeCUView')
        ),
        exact: true,
        path: '/app/management/coupon-codes/edit/:code'
      },
      {
        component: lazy(() =>
          import('src/views/management/couponCodes/CouponCodeListView')
        ),
        exact: true,
        path: '/app/management/coupon-codes'
      },
      // Programs
      {
        component: lazy(() =>
          import('src/views/management/program/ProgramCUView')
        ),
        exact: true,
        path: '/app/management/programs/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/program/ProgramCUView')
        ),
        exact: true,
        path: '/app/management/programs/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/program/ProgramListView')
        ),
        exact: true,
        path: '/app/management/programs'
      },
      // Challenges
      {
        component: lazy(() =>
          import('src/views/management/challenge/ChallengeCUView')
        ),
        exact: true,
        path: '/app/management/challenge/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/challenge/ChallengeCUView')
        ),
        exact: true,
        path: '/app/management/challenge/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/challenge/ChallengeListView')
        ),
        exact: true,
        path: '/app/management/challenge'
      },
      // Mini challenges
      {
        component: lazy(() =>
          import('src/views/management/miniChallenge/MiniChallengeCUView')
        ),
        exact: true,
        path: '/app/management/mini-challenge/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/miniChallenge/MiniChallengeCUView')
        ),
        exact: true,
        path: '/app/management/mini-challenge/edit/:id'
      },

      {
        component: lazy(() =>
          import('src/views/management/miniChallenge/MiniChallengeListView')
        ),
        exact: true,
        path: '/app/management/mini-challenge'
      },

      // Goals
      {
        component: lazy(() => import('src/views/management/goal/GoalListView')),
        exact: true,
        path: '/app/management/goal'
      },
      {
        component: lazy(() => import('src/views/management/goal/GoalCUView')),
        exact: true,
        path: '/app/management/goal/create'
      },
      {
        component: lazy(() => import('src/views/management/goal/GoalCUView')),
        exact: true,
        path: '/app/management/goal/edit/:id'
      },
      //  Improvement Areas
      {
        component: lazy(() =>
          import('src/views/management/improvementArea/ImprovementAreaListView')
        ),
        exact: true,
        path: '/app/management/improvement-area'
      },
      {
        component: lazy(() =>
          import('src/views/management/improvementArea/ImprovementAreaCUView')
        ),
        exact: true,
        path: '/app/management/improvement-area/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/improvementArea/ImprovementAreaCUView')
        ),
        exact: true,
        path: '/app/management/improvement-area/edit/:id'
      },
      // Badges
      {
        component: lazy(() => import('src/views/management/badge/BadgeCUView')),
        exact: true,
        path: '/app/management/badges/create'
      },
      {
        component: lazy(() => import('src/views/management/badge/BadgeCUView')),
        exact: true,
        path: '/app/management/badges/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/badge/BadgeListView')
        ),
        exact: true,
        path: '/app/management/badges'
      },
      // Articles
      {
        component: lazy(() =>
          import('src/views/management/article/ArticleCUView')
        ),
        exact: true,
        path: '/app/management/article/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/article/ArticleListView')
        ),
        exact: true,
        path: '/app/management/article'
      },
      {
        component: lazy(() =>
          import('src/views/management/article/ArticleCUView')
        ),
        exact: true,
        path: '/app/management/article/edit/:id'
      },
      // Recipes
      {
        component: lazy(() =>
          import('src/views/management/recipe/RecipeCUView')
        ),
        exact: true,
        path: '/app/management/recipe/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/recipe/RecipeCUView')
        ),
        exact: true,
        path: '/app/management/recipe/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/recipe/RecipeListView')
        ),
        exact: true,
        path: '/app/management/recipe'
      },
      // Recipes Types
      {
        component: lazy(() =>
          import('src/views/management/recipeType/RecipeTypeCUView')
        ),
        exact: true,
        path: '/app/management/recipe-type/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/recipeType/RecipeTypeCUView')
        ),
        exact: true,
        path: '/app/management/recipe-type/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/recipeType/RecipeTypeListView')
        ),
        exact: true,
        path: '/app/management/recipe-type'
      },
      // Recipe Meal Types
      {
        component: lazy(() => import('src/views/management/recipeMealType')),
        exact: true,
        path: '/app/management/meal-types'
      },
      // Onboard
      {
        component: lazy(() =>
          import('src/views/management/onboard/OnboardCUView')
        ),
        exact: true,
        path: '/app/management/onboard/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/onboard/OnboardCUView')
        ),
        exact: true,
        path: '/app/management/onboard/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/onboard/OnboardListView')
        ),
        exact: true,
        path: '/app/management/onboard'
      },
      // Onboard Survey
      {
        component: lazy(() =>
          import('src/views/management/onboardSurvey/OnboardSurveyCUView')
        ),
        exact: true,
        path: '/app/management/onboard-surveys/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/onboardSurvey/OnboardSurveyCUView')
        ),
        exact: true,
        path: '/app/management/onboard-surveys/edit/:id'
      },
      {
        component: lazy(() =>
          import('src/views/management/onboardSurvey/OnboardSurveyListView')
        ),
        exact: true,
        path: '/app/management/onboard-surveys'
      },
      // Health Problems
      {
        component: lazy(() =>
          import('src/views/management/healthProblem/HealthProblemListView')
        ),
        exact: true,
        path: '/app/management/health-problem'
      },
      {
        component: lazy(() =>
          import('src/views/management/healthProblem/HealthProblemCUView')
        ),
        exact: true,
        path: '/app/management/health-problem/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/healthProblem/HealthProblemCUView')
        ),
        exact: true,
        path: '/app/management/health-problem/edit/:id'
      },
      // Playlist
      // playlist items
      {
        component: lazy(() =>
          import('src/views/management/playlist/PlayListDetailsView')
        ),
        exact: true,
        path: '/app/management/playlists'
      },
      {
        component: lazy(() =>
          import('src/views/management/playlist/PlayListCUView')
        ),
        exact: true,
        path: '/app/management/playlists/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/playlist/PlayListCUView')
        ),
        exact: true,
        path: '/app/management/playlists/edit/:id'
      },

      // playlist category
      {
        component: lazy(() =>
          import('src/views/management/playlist/PlayListCategoryListView')
        ),
        exact: true,
        path: '/app/management/playlists/categories'
      },
      // Infomations
      {
        component: lazy(() =>
          import('src/views/management/information/InformationListView')
        ),
        exact: true,
        path: '/app/management/informations'
      },
      {
        component: lazy(() =>
          import('src/views/management/information/InformationCUView')
        ),
        exact: true,
        path: '/app/management/informations/add'
      },
      {
        component: lazy(() =>
          import('src/views/management/information/InformationCUView')
        ),
        exact: true,
        path: '/app/management/informations/edit/:id'
      },
      // General FAQ
      {
        component: lazy(() => import('src/views/management/faq')),
        exact: true,
        path: '/app/management/general-faq'
      },

      // User Inputs
      {
        component: lazy(() =>
          import('src/views/management/userInput/UserInputListView')
        ),
        exact: true,
        path: '/app/management/user-input'
      },
      {
        component: lazy(() =>
          import('src/views/management/userInput/UserInputCUView')
        ),
        exact: true,
        path: '/app/management/user-input/create'
      },
      {
        component: lazy(() =>
          import('src/views/management/userInput/UserInputCUView')
        ),
        exact: true,
        path: '/app/management/user-input/edit/:id'
      },
      // Notification
      {
        component: lazy(() =>
          import('src/views/management/notification/SendNotificationView')
        ),
        exact: true,
        path: '/app/management/send-notification'
      },
      // Static Config
      {
        component: lazy(() =>
          import('src/views/management/config/StaticConfigView')
        ),
        exact: true,
        path: '/app/management/static-config'
      },
      {
        component: lazy(() =>
          import(
            'src/views/management/config/StaticConfigView/StaticConfigFolderView'
          )
        ),
        exact: true,
        path: '/app/management/static-config/:version'
      },
      {
        component: lazy(() =>
          import(
            'src/views/management/config/StaticConfigView/StaticConfigFolderView'
          )
        ),
        exact: true,
        path: '/app/management/static-config/:version/:platform'
      },
      {
        component: lazy(() =>
          import(
            'src/views/management/config/StaticConfigView/StaticConfigFolderView'
          )
        ),
        exact: true,
        path: '/app/management/static-config/:version/:platform/:language/'
      },

      // App Config
      {
        component: lazy(() =>
          import('src/views/management/config/AppConfigUpdateView')
        ),
        exact: true,
        path: '/app/management/app-config'
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
