import { gql } from 'apollo-boost';

export const ALL_MINI_CHALLENGES = gql`
  query getMiniChallengeList($filter: MiniChallengeFilter) {
    getMiniChallengeList(filter: $filter) {
      id
      name
      duration
      bannerImage
      bannerImageSmall
      premium
      shortDescription
      userBadges {
        badge
      }
      updatedAt
      published
      createdAt
    }
  }
`;

export const ALL_MINI_CHALLENGES_PANEL = gql`
  query getMiniChallengeListPanel($filter: MiniChallengesFilter!) {
    getMiniChallengeListPanel(filter: $filter) {
      miniChallenges {
        id
        name
        bannerImage
        bannerImageSmall
        published
        inProgram
        createdAt
      }
      totalCount
    }
  }
`;

export const GET_MINI_CHALLENGE = gql`
  query getMiniChallenge($id: String!) {
    getMiniChallenge(id: $id) {
      id
      premium
      bannerImage
      bannerImageSmall
      video
      videoBanner
      videoDescription
      videoTitle
      videos {
        id
        url
        thumbnail
        title
        description
      }
      description
      duration
      name
      published
      featured
      inProgram
      shortDescription
      notifications {
        id
        name
        userInput {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        userInputs {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        message
        duration
        repeating
        absoluteTime
        type {
          id
          name
        }
      }
      benefits {
        id
        name
        description
      }
      benefitsTitle
      tasks
      improvementAreas {
        id
        name
      }
    }
  }
`;

export const CREATE_MINI_CHALLENGE = gql`
  mutation createMiniChallenge(
    $bannerImage: String
    $bannerImageSmall: String
    $video: String
    $videoBanner: String
    $videoDescription: String
    $videoTitle: String
    $videos: String
    $description: String
    $improvementAreas: [String]
    $duration: Int
    $name: String!
    $shortDescription: String
    $benefitsTitle: String
    $premium: Boolean
    $featured: Boolean
    $tasks: String
    $benefits: String
    $totalHealthyCoins: String
    $totalExperiencePoints: String
    $notifications: String
    $published: Boolean
    $inProgram: Boolean
  ) {
    createMiniChallenge(
      bannerImage: $bannerImage
      bannerImageSmall: $bannerImageSmall
      video: $video
      videoBanner: $videoBanner
      videoDescription: $videoDescription
      videoTitle: $videoTitle
      videos: $videos
      description: $description
      duration: $duration
      name: $name
      shortDescription: $shortDescription
      tasks: $tasks
      benefits: $benefits
      benefitsTitle: $benefitsTitle
      premium: $premium
      featured: $featured
      improvementAreas: $improvementAreas
      totalHealthyCoins: $totalHealthyCoins
      totalExperiencePoints: $totalExperiencePoints
      notifications: $notifications
      published: $published
      inProgram: $inProgram
    ) {
      id
      premium
      published
      featured
      inProgram
      bannerImage
      bannerImageSmall
      video
      videoBanner
      videoDescription
      videoTitle
      videos {
        id
        url
        thumbnail
        title
        description
      }
      description
      duration
      name
      shortDescription
      notifications {
        id
        name
        userInput {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        userInputs {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        message
        duration
        repeating
        absoluteTime
        type {
          id
          name
        }
      }
      benefits {
        id
        description
      }
      benefitsTitle
      tasks
      improvementAreas {
        id
        name
      }
    }
  }
`;

export const UPDATE_MINI_CHALLENGE = gql`
  mutation updateMiniChallenge(
    $id: String!
    $bannerImage: String
    $bannerImageSmall: String
    $video: String
    $videoBanner: String
    $videoDescription: String
    $videoTitle: String
    $videos: String
    $description: String
    $duration: Int
    $name: String!
    $shortDescription: String
    $tasks: String
    $benefits: String
    $benefitsTitle: String
    $premium: Boolean
    $featured: Boolean
    $totalHealthyCoins: String
    $totalExperiencePoints: String
    $notifications: String
    $improvementAreas: [String]
    $published: Boolean
    $inProgram: Boolean
  ) {
    updateMiniChallenge(
      id: $id
      bannerImage: $bannerImage
      bannerImageSmall: $bannerImageSmall
      video: $video
      videoBanner: $videoBanner
      videoDescription: $videoDescription
      videoTitle: $videoTitle
      videos: $videos
      description: $description
      duration: $duration
      name: $name
      shortDescription: $shortDescription
      tasks: $tasks
      benefits: $benefits
      benefitsTitle: $benefitsTitle
      premium: $premium
      improvementAreas: $improvementAreas
      published: $published
      featured: $featured
      inProgram: $inProgram
      totalHealthyCoins: $totalHealthyCoins
      totalExperiencePoints: $totalExperiencePoints
      notifications: $notifications
    ) {
      id
      premium
      bannerImage
      bannerImageSmall
      video
      videoBanner
      videoDescription
      videoTitle
      videos {
        id
        url
        thumbnail
        title
        description
      }
      description
      duration
      published
      featured
      inProgram
      name
      shortDescription
      notifications {
        id
        name
        userInput {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        userInputs {
          id
          name
          inputId
          type
          question
          placeholder
          defaultValue
        }
        message
        duration
        repeating
        absoluteTime
        type {
          id
          name
        }
      }
      benefits {
        id
        description
      }
      benefitsTitle
      tasks
      improvementAreas {
        id
        name
      }
    }
  }
`;
