/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT'
};

export const DEFAULT_LANGUAGE = 'en';

export const AVAILABLE_LANGUAGES = {
  en: 'en',
  ru: 'ru',
  tr: 'tr',
};
