import { gql } from 'apollo-boost';

export const ALL_BOARDS = gql`
  query getCommunityBoardsPanel($showCorporateOnly: Boolean!) {
    getCommunityBoardsPanel(showCorporateOnly: $showCorporateOnly) {
      id
      name
      image
      communityId
      communityType
      published
    }
  }
`;

export const GET_BOARD = gql`
  query getCommunityBoard($id: String) {
    getCommunityBoard(id: $id) {
      board {
        id
        name
        image
        communityId
        communityType
        published
      }
      memberCount
      joined
    }
  }
`;

export const CREATE_BOARD = gql`
  mutation createBoard(
    $name: String!
    $image: String!
    $communityId: String!
    $communityType: CommunityType!
    $published: Boolean
  ) {
    createBoard(
      name: $name
      image: $image
      communityId: $communityId
      communityType: $communityType
      published: $published
    ) {
      id
      name
      image
      communityId
      communityType
      published
    }
  }
`;

export const UPDATE_BOARD = gql`
  mutation updateBoard(
    $boardId: String!
    $name: String!
    $image: String!
    $published: Boolean
  ) {
    updateBoard(
      boardId: $boardId
      name: $name
      image: $image
      published: $published
    ) {
      id
      name
      image
      communityId
      communityType
      published
    }
  }
`;

export const DELETE_BOARD = gql`
  mutation deleteBoard($id: String!) {
    deleteBoard(id: $id)
  }
`;

export const ALL_COMMUNITY_POSTS = gql`
  query getCommunityPosts($filter: CommunityPostFilter) {
    getCommunityPosts(filter: $filter) {
      post {
        id
        content
        image
        published
        isPrivate
        video
        videoThumbnail
        board {
          id
          name
        }
        owner {
          id
          email
          profilePhoto
        }
        createdAt
      }
      commentCount
      likesCount
    }
  }
`;

export const GET_POST = gql`
  query getCommunityPost($postId: String!) {
    getCommunityPost(postId: $postId) {
      post {
        id
        content
        createdAt
        image
        isPrivate
        published
        video
        videoThumbnail
        board {
          id
          name
        }
        owner {
          id
          email
          profilePhoto
          type
        }
        comments {
          id
          published
          comment
          owner {
            id
            email
          }
          likesCount
          replies {
            id
            comment
            createdAt
            owner {
              id
              email
            }
            likesCount
            isLiked
            published
          }
          createdAt
        }
      }
      commentCount
      likesCount
    }
  }
`;

export const CREATE_POST = gql`
  mutation createCommunityPost(
    $content: String!
    $image: String
    $video: String
    $videoThumbnail: String
    $published: Boolean
    $isPrivate: Boolean
    $boardId: String!
    $ownerId: String
  ) {
    createCommunityPost(
      content: $content
      image: $image
      video: $video
      videoThumbnail: $videoThumbnail
      published: $published
      isPrivate: $isPrivate
      boardId: $boardId
      ownerId: $ownerId
    ) {
      id
      content
      image
      video
      videoThumbnail
      published
      isPrivate
      createdAt
    }
  }
`;

export const UPDATE_POST = gql`
  mutation updateCommunityPost(
    $postId: String!
    $content: String!
    $image: String
    $isPrivate: Boolean
    $video: String
    $videoThumbnail: String
    $boardId: String!
    $ownerId: String
    $published: Boolean
  ) {
    updateCommunityPost(
      postId: $postId
      content: $content
      image: $image
      video: $video
      videoThumbnail: $videoThumbnail
      published: $published
      isPrivate: $isPrivate
      boardId: $boardId
      ownerId: $ownerId
    ) {
      id
      content
      image
      video
      videoThumbnail
      published
      createdAt
    }
  }
`;

export const DELETE_POST = gql`
  mutation deletePost($id: String!) {
    deletePost(id: $id) {
      id
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation createCommunityComment(
    $comment: String!
    $postId: String!
    $published: Boolean
  ) {
    createCommunityComment(
      comment: $comment
      postId: $postId
      published: $published
    ) {
      id
      comment
      owner {
        id
        email
      }
      published
      createdAt
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation updateCommunityComment(
    $commentId: String!
    $comment: String!
    $postId: String!
    $ownerId: String!
    $published: Boolean
  ) {
    updateCommunityComment(
      commentId: $commentId
      comment: $comment
      postId: $postId
      ownerId: $ownerId
      published: $published
    ) {
      id
      comment
      owner {
        id
        email
      }
      published
      createdAt
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteCommunityComment($commentId: String!) {
    deleteCommunityComment(commentId: $commentId)
  }
`;

export const GET_COMMENT = gql`
  query getCommunityComment($commentId: String!) {
    getCommunityComment(commentId: $commentId) {
      comment {
        id
        published
        comment
        owner {
          id
          email
        }
        likesCount
        replies {
          id
          comment
          createdAt
          owner {
            id
            email
          }
          likesCount
          isLiked
          published
        }
        createdAt
      }
      isLiked
      likesCount
    }
  }
`;

export const GET_LATEST_COMMENTS = gql`
  query GetCommunityComments($filter: CommunityCommentFilter) {
    getLatestCommunityComments(filter: $filter) {
      comments {
        id
        comment
        published
        createdAt
        communityPost {
          id
          board {
            id
            name
            image
          }
        }
        owner {
          id
          email
        }
      }
      total
    }
  }
`;
