import { gql } from 'apollo-boost';

export const GET_CORPORATIONS = gql`
  query getCorporationListPanel($filter: CorporationFilter!) {
    getCorporationListPanel(filter: $filter) {
      corporations {
        id
        name
        image
        createdAt
      }
      totalCount
    }
  }
`;

export const GET_CORPORATION = gql`
  query getCorporation($id: String!) {
    getCorporation(id: $id) {
      id
      name
      image
      description
      codes {
        code
        isUsed
      }
      users {
        id
        email
        firstName
        lastName
        createdAt
        promoCodes {
          code
        }
      }
      admins {
        id
        email
      }
      departments {
        id
        name
      }
      goals {
        id
        name
      }
    }
  }
`;

export const GET_CORPORATION_DEPARTMENT_LIST = gql`
  query getCorporationDepartmentList {
    getCorporationDepartmentList {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const GET_CORPORATION_LIST_DEPARTMENT_PANEL = gql`
  query getCorporationDepartmentListPanel($filter: CorporationFilter!) {
    getCorporationDepartmentListPanel(filter: $filter) {
      departments {
        id
        name
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

export const CREATE_CORPORATION = gql`
  mutation createCorporation(
    $name: String!
    $description: String
    $image: String!
    $adminIds: [String]
    $departmentIds: [String]
    $goalIds: [String]
  ) {
    createCorporation(
      name: $name
      description: $description
      image: $image
      adminIds: $adminIds
      departmentIds: $departmentIds
      goalIds: $goalIds
    ) {
      id
      name
      description
      image
      admins {
        id
        email
      }
      departments {
        id
        name
      }
      goals {
        id
        name
      }
    }
  }
`;

export const EDIT_CORPORATION = gql`
  mutation editCorporation(
    $id: String!
    $name: String!
    $description: String
    $image: String!
    $adminIds: [String]
    $departmentIds: [String]
    $goalIds: [String]
  ) {
    editCorporation(
      id: $id
      name: $name
      description: $description
      image: $image
      adminIds: $adminIds
      departmentIds: $departmentIds
      goalIds: $goalIds
    ) {
      id
      name
      description
      image
      admins {
        id
        email
      }
      departments {
        id
        name
      }
      goals {
        id
        name
      }
    }
  }
`;

export const CREATE_CORPORATION_DEPARTMENT = gql`
  mutation createCorporationDepartment($name: String!) {
    createCorporationDepartment(name: $name) {
      id
      name
    }
  }
`;

export const EDIT_CORPORATION_DEPARTMENT = gql`
  mutation editCorporationDepartment($id: ID!, $name: String!) {
    editCorporationDepartment(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const GET_CORPORATION_GOAL_LIST = gql`
  query getCorporationGoalList {
    getCorporationGoalList {
      id
      name
    }
  }
`;

export const EXPORT_PROMO_CODES = gql`
  query exportPromoCodes($corporationId: String!) {
    exportPromoCodes(corporationId: $corporationId)
  }
`;

export const REMOVE_USER_FROM_CORPORATION = gql`
  mutation removeCorporateUser($userId: String!, $corporateId: String!) {
    removeCorporateUser(userId: $userId, corporateId: $corporateId) {
      id
      email
    }
  }
`;
