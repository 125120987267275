import { gql } from 'apollo-boost';

export const CREATE_BADGE = gql`
  mutation createBadge(
    $name: String!
    $image: String!
    $level: Int
    $type: BadgeType
    $challengeId: ID
    $programId: ID
  ) {
    createBadge(
      name: $name
      level: $level
      image: $image
      type: $type
      challengeId: $challengeId
      programId: $programId
    ) {
      id
      name
      image
      level
      type
    }
  }
`;

export const UPDATE_BADGE = gql`
  mutation updateBadge(
    $id: ID!
    $name: String
    $image: String
    $level: Int
    $type: BadgeType
    $challengeId: ID
    $programId: ID
  ) {
    updateBadge(
      id: $id
      name: $name
      level: $level
      image: $image
      type: $type
      challengeId: $challengeId
      programId: $programId
    ) {
      id
      name
      image
      level
      type
    }
  }
`;

export const ALL_BADGE_LIST = gql`
  query getBadgeList {
    getBadgeList {
      id
      name
      image
      level
      type
      createdAt
      updatedAt
    }
  }
`;

export const ALL_BADGE_LIST_PANEL = gql`
  query getBadgeListPanel($filter: BadgeFilter!) {
    getBadgeListPanel(filter: $filter) {
      badges {
        id
        name
        image
        level
        type
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

export const GET_BADGE = gql`
  query getBadge($id: ID!) {
    getBadge(id: $id) {
      id
      name
      image
      level
      type
      challenge {
        id
        name
      }
      program {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
