import { gql } from 'apollo-boost';

export const ALL_INFORMATION = gql`
  query getPopupInformationList($filter: PopupFilter) {
    getPopupInformationList(filter: $filter) {
      id
      type
      popup {
        title
      }
      active
    }
  }
`;

export const GET_INFORMATION = gql`
  query getPopupInformation($id: String!) {
    getPopupInformation(id: $id) {
      id
      type
      popup {
        title
        description
      }
      active
    }
  }
`;

export const GET_INFO_TYPES = gql`
  query getAvailableTypes {
    getAvailableTypes
  }
`;

export const ADD_INFORMATION = gql`
  mutation addPopupInformation(
    $type: InfoPopupType!
    $popup: String!
    $active: Boolean!
  ) {
    addPopupInformation(type: $type, popup: $popup, active: $active) {
      id
      type
      popup {
        title
        description
      }
      active
    }
  }
`;

export const EDIT_INFORMATION = gql`
  mutation editPopupInformation(
    $id: String!
    $type: InfoPopupType!
    $popup: String!
    $active: Boolean!
  ) {
    editPopupInformation(id: $id, type: $type, popup: $popup, active: $active) {
      id
      type
      popup {
        title
        description
      }
      active
    }
  }
`;
