import { gql } from 'apollo-boost';

export const GET_SECTION = gql`
  query getSection($id: String!) {
    getSection(id: $id) {
      id
      name
      goals {
        id
        name
      }
    }
  }
`;

export const CREATE_SECTION = gql`
  mutation createSection($name: String!, $goalIds: [String]) {
    createSection(name: $name, goalIds: $goalIds) {
      id
      name
      goals {
        id
        name
      }
    }
  }
`;

export const UPDATE_SECTION = gql`
  mutation updateSection($id: String!, $name: String, $goalIds: [String]) {
    updateSection(id: $id, name: $name, goalIds: $goalIds) {
      id
      name
      goals {
        id
        name
      }
    }
  }
`;

export const UPDATE_HEALTH_PROBLEM = gql`
  mutation updateHealthProblem(
    $id: String!
    $name: String
    $description: String
  ) {
    updateHealthProblem(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const CREATE_HEALTH_PROBLEM = gql`
  mutation createHealthProblem($description: String, $name: String!) {
    createHealthProblem(description: $description, name: $name) {
      id
      name
      description
    }
  }
`;

export const ALL_HEALTH_PROBLEMS = gql`
  {
    getHealthProblemList {
      id
      name
      description
    }
  }
`;

export const ALL_HEALTH_PROBLEMS_PANEL = gql`
  query getHealthProblemListPanel($filter: HealthProblemFilter!) {
    getHealthProblemListPanel(filter: $filter) {
      healthProblems {
        id
        name
      }
      totalCount
    }
  }
`;

export const GET_HEALTH_PROBLEM = gql`
  query getHealthProblem($id: String!) {
    getHealthProblem(id: $id) {
      id
      name
      description
    }
  }
`;

export const UPDATE_GOAL = gql`
  mutation updateGoal(
    $id: String!
    $name: String
    $image: String
    $type: GoalType
  ) {
    updateGoal(id: $id, name: $name, image: $image, type: $type) {
      id
      name
      image
      type
    }
  }
`;

export const GET_GOAL = gql`
  query getGoal($id: String!) {
    getGoal(id: $id) {
      id
      name
      image
      type
    }
  }
`;

export const ALL_GOALS = gql`
  {
    getGoalList {
      id
      name
      type
    }
  }
`;

export const ALL_GOALS_PANEL = gql`
  query getGoalListPanel($filter: GoalFilter!) {
    getGoalListPanel(filter: $filter) {
      goals {
        id
        name
        image
      }
      totalCount
    }
  }
`;

export const CREATE_GOAL = gql`
  mutation createGoal($name: String!, $image: String, $type: GoalType!) {
    createGoal(name: $name, image: $image, type: $type) {
      id
      name
      image
      type
    }
  }
`;

export const UPDATE_IMPROVEMENT_AREA = gql`
  mutation updateImprovementArea(
    $id: String!
    $goals: [String]
    $name: String
  ) {
    updateImprovementArea(id: $id, name: $name, goals: $goals) {
      id
      name
      goals {
        id
        name
      }
    }
  }
`;

export const GET_IMPROVEMENT_AREA = gql`
  query getImprovementArea($id: String!) {
    getImprovementArea(id: $id) {
      id
      name
      goals {
        id
        name
      }
    }
  }
`;

export const ALL_IMPROVEMENT_AREAS = gql`
  {
    getImprovementAreaList {
      id
      name
    }
  }
`;

export const ALL_IMPROVEMENT_AREAS_PANEL = gql`
  query getImprovementAreaListPanel($filter: ImprovementAreaFilter!) {
    getImprovementAreaListPanel(filter: $filter) {
      improvementAreas {
        id
        name
      }
      totalCount
    }
  }
`;

export const CREATE_IMPROVEMENT_AREA = gql`
  mutation createImprovementArea($name: String!, $goals: [String]) {
    createImprovementArea(name: $name, goals: $goals) {
      id
      name
      goals {
        id
        name
      }
    }
  }
`;
