import { gql } from 'apollo-boost';

export const ALL_CAMPAIGNS = gql`
  query getCampaigns($filter: CampaignFilter) {
    getCampaigns(filter: $filter) {
      campaigns {
        id
        image
        key
        title
        published
        createdAt
      }
      total
    }
  }
`;

export const GET_CAMPAIGN = gql`
  query getCampaign($id: String, $key: String) {
    getCampaign(id: $id, key: $key) {
      id
      image
      key
      title
      period
      published
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign(
    $buttonText: String
    $description: String
    $image: String
    $key: String
    $url: String
    $title: String
    $period: String
    $published: Boolean
  ) {
    createCampaign(
      buttonText: $buttonText
      description: $description
      image: $image
      key: $key
      url: $url
      title: $title
      period: $period
      published: $published
    ) {
      id
      buttonText
      description
      image
      key
      url
      title
      period
      published
      createdAt
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign(
    $id: String!
    $buttonText: String
    $description: String
    $image: String
    $key: String
    $url: String
    $title: String
    $period: String
    $published: Boolean
  ) {
    updateCampaign(
      id: $id
      buttonText: $buttonText
      description: $description
      image: $image
      key: $key
      url: $url
      title: $title
      period: $period
      published: $published
    ) {
      id
      buttonText
      description
      image
      key
      url
      title
      period
      published
      createdAt
    }
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation deleteCampaign($id: String!) {
    deleteCampaign(id: $id)
  }
`;
